import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import Image from '../components/image'
import SEO from '../components/seo'

import Checkout from '../components/checkout'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    {/* <h1>Hi people</h1>
    <p>Welcome to your new Gatsby e-commerce site.</p>
    <p>
      Follow{' '}
      <a href="https://www.gatsbyjs.org/tutorial/ecommerce-tutorial/">
        this tutorial
      </a>{' '}
      to build your own.
    </p> */}
    {/* <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div> */}
    <br />
    <br />
    <Link to="/advanced">菲哥 Stripe 測試</Link>
    <br />
    <br />
    <a href="https://dashboard.stripe.com/test/dashboard" target="_blank">
      Stripe Dashboard
    </a>
    <br />
    eric@ynltour.com
    <br />
    <br />
    <Checkout />
  </Layout>
)

export default IndexPage
